import React, { useState, useCallback, useMemo } from "react"
import Link from "next/link"
import { useRouter } from "next/router"
import styled from "styled-components"
import { slide as Menu } from "react-burger-menu"
import Avatar from "@material-ui/core/Avatar"
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded"
import OpenInNewIcon from "@material-ui/icons/OpenInNew"
import { CDN_DOMAIN } from "../../config/cdn"
import typesURLMatching from "../../datas/typesURLMatching"
import { generateObfuscatedLink } from "../../helpers/SEO"

const Button = styled.a`
  width: ${props => props.width || 150}px;
`

const ButtonWrapper = styled.div`
  max-width: ${props => props.maxWidth || 150}px;
`

const UECHeader = ({ config, hideLinks = [], hideProButton, hidePriceButton }) => {
  const router = useRouter()

  const menus = useMemo(() => ({
    "/index": {
      menu: [
        {
          label: "Accueil",
          link: `https://${config.websiteDomain}`,
          mobileOnly: true
        },
        {
          label: "Baromètres Loyers et Prix",
          link: "#",
          dropdown: [
            {
              label: "Bureaux",
              link: `/${typesURLMatching['bureau']}`
            },
            {
              label: "Locaux commerciaux",
              link: `/${typesURLMatching['local-commercial']}`
            },
            {
              label: "Fonds de commerce",
              link: `/${typesURLMatching['fonds-commerce']}`
            },
            {
              label: "Pop-up store",
              link: `/${typesURLMatching['popup-store']}`
            },
            {
              label: "Coworking",
              link: `/${typesURLMatching['coworking']}`
            },
            {
              label: "Entrepôts / Activités",
              link: `/${typesURLMatching['entrepot']}`
            },
            {
              label: "Terrains",
              link: `/${typesURLMatching['terrain']}`
            }
          ]
        },
        {
          label: "Choisir une agence",
          link: "/agence-immobiliere",
          nolink: true
        },
        {
          label: "Evènements",
          link: "https://blog.unemplacement.com/petits-dejeuners/",
          blank: true
        },
        {
          label: "Guide de l‘immobilier",
          link: `/guide`,
          nolink: true
        }
      ].filter(o => !hideLinks.includes(o.link)),
      button: {
        label: "Connexion",
        link: `https://${config.appDomain}`,
        width: 125
      }
    },
    "/professionnels-immobilier": {
      menu: [
        {
          label: "Accueil",
          link: `https://${config.websiteDomain}`,
          mobileOnly: true
        },
        {
          label: "Baromètres Loyers et Prix",
          link: "#",
          dropdown: [
            {
              label: "Bureaux",
              link: `/${typesURLMatching['bureau']}`
            },
            {
              label: "Locaux commerciaux",
              link: `/${typesURLMatching['local-commercial']}`
            },
            {
              label: "Fonds de commerce",
              link: `/${typesURLMatching['fonds-commerce']}`
            },
            {
              label: "Pop-up store",
              link: `/${typesURLMatching['popup-store']}`
            },
            {
              label: "Coworking",
              link: `/${typesURLMatching['coworking']}`
            },
            {
              label: "Entrepôts / Activités",
              link: `/${typesURLMatching['entrepot']}`
            },
            {
              label: "Terrains",
              link: `/${typesURLMatching['terrain']}`
            }
          ]
        },
        {
          label: "Choisir une agence",
          link: "/agence-immobiliere",
          nolink: true
        },
        {
          label: "Evènements",
          link: "https://blog.unemplacement.com/petits-dejeuners/",
          blank: true
        },
        {
          label: "Guide de l‘immobilier",
          link: `/guide`,
          nolink: true
        }
      ],
      button: {
        label: "Connexion",
        link: `https://${config.appDomain}`,
        width: 125
      }
    },
    "/collectivites-territoriales": {
      menu: [
        {
          label: "Accueil",
          link: `https://${config.websiteDomain}`,
          mobileOnly: true
        },
        {
          label: "Baromètres Loyers et Prix",
          link: "#",
          dropdown: [
            {
              label: "Bureaux",
              link: `/${typesURLMatching['bureau']}`
            },
            {
              label: "Local commercial",
              link: `/${typesURLMatching['local-commercial']}`
            },
            {
              label: "Fonds de commerce",
              link: `/${typesURLMatching['fonds-commerce']}`
            },
            {
              label: "Pop-up store",
              link: `/${typesURLMatching['popup-store']}`
            },
            {
              label: "Coworking",
              link: `/${typesURLMatching['coworking']}`
            },
            {
              label: "Entrepôts / Activités",
              link: `/${typesURLMatching['entrepot']}`
            },
            {
              label: "Terrains",
              link: `/${typesURLMatching['terrain']}`
            },
            {
              label: "Confier ma recherche",
              link: "/",
              customClass: "uec-dropdown-button"
            }
          ]
        },
        {
          label: "Evènements",
          link: "https://blog.unemplacement.com/petits-dejeuners/",
          blank: true
        }
      ],
      button: {
        label: "Connexion",
        link: `https://${config.appDomain}`,
        width: 125
      }
    },
    "/services-tarifs": {
      menu: [
        {
          label: "Accueil",
          link: `https://${config.websiteDomain}`,
          mobileOnly: true
        },
        {
          label: "Baromètres Loyers et Prix",
          link: "#",
          dropdown: [
            {
              label: "Bureaux",
              link: `/${typesURLMatching['bureau']}`
            },
            {
              label: "Local commercial",
              link: `/${typesURLMatching['local-commercial']}`
            },
            {
              label: "Fonds de commerce",
              link: `/${typesURLMatching['fonds-commerce']}`
            },
            {
              label: "Pop-up store",
              link: `/${typesURLMatching['popup-store']}`
            },
            {
              label: "Coworking",
              link: `/${typesURLMatching['coworking']}`
            },
            {
              label: "Entrepôts / Activités",
              link: `/${typesURLMatching['entrepot']}`
            },
            {
              label: "Terrains",
              link: `/${typesURLMatching['terrain']}`
            }
          ]
        },
        {
          label: "Choisir une agence",
          link: "/agence-immobiliere",
          nolink: true
        },
        {
          label: "Evènements",
          link: "https://blog.unemplacement.com/petits-dejeuners/",
          blank: true
        },
        {
          label: "Guide de l‘immobilier",
          link: `/guide`,
          nolink: true
        }
      ],
      button: {
        label: "Connexion",
        link: `https://${config.appDomain}`,
        width: 125
      }
    },
    "/references": {
      menu: [
        {
          label: "Accueil",
          link: `https://${config.websiteDomain}`,
          mobileOnly: true
        },
        {
          label: "Baromètres Loyers et Prix",
          link: "#",
          dropdown: [
            {
              label: "Bureaux",
              link: `/${typesURLMatching['bureau']}`
            },
            {
              label: "Local commercial",
              link: `/${typesURLMatching['local-commercial']}`
            },
            {
              label: "Fonds de commerce",
              link: `/${typesURLMatching['fonds-commerce']}`
            },
            {
              label: "Pop-up store",
              link: `/${typesURLMatching['popup-store']}`
            },
            {
              label: "Coworking",
              link: `/${typesURLMatching['coworking']}`
            },
            {
              label: "Entrepôts / Activités",
              link: `/${typesURLMatching['entrepot']}`
            },
            {
              label: "Terrains",
              link: `/${typesURLMatching['terrain']}`
            },
            {
              label: "Confier ma recherche",
              link: "/",
              customClass: "uec-dropdown-button"
            }
          ]
        },
        {
          label: "Evènements",
          link: "https://blog.unemplacement.com/petits-dejeuners/",
          blank: true
        }
      ],
      button: {
        label: "Connexion",
        link: `https://${config.appDomain}`,
        width: 125
      }
    },
    "/recommandations": {
      menu: [
        {
          label: "Accueil",
          link: `https://${config.websiteDomain}`,
          mobileOnly: true
        },
        {
          label: "Baromètres Loyers et Prix",
          link: "#",
          dropdown: [
            {
              label: "Bureaux",
              link: `/${typesURLMatching['bureau']}`
            },
            {
              label: "Local commercial",
              link: `/${typesURLMatching['local-commercial']}`
            },
            {
              label: "Fonds de commerce",
              link: `/${typesURLMatching['fonds-commerce']}`
            },
            {
              label: "Pop-up store",
              link: `/${typesURLMatching['popup-store']}`
            },
            {
              label: "Coworking",
              link: `/${typesURLMatching['coworking']}`
            },
            {
              label: "Entrepôts / Activités",
              link: `/${typesURLMatching['entrepot']}`
            },
            {
              label: "Terrains",
              link: `/${typesURLMatching['terrain']}`
            },
            {
              label: "Confier ma recherche",
              link: "/",
              customClass: "uec-dropdown-button"
            }
          ]
        },
        {
          label: "Evènements",
          link: "https://blog.unemplacement.com/petits-dejeuners/",
          blank: true
        }
      ],
      button: {
        label: "Connexion",
        link: `https://${config.appDomain}`,
        width: 125
      }
    }
  }), [config, hideLinks])

  const [openedDropdown, setOpenedDropdown] = useState({})

  const toggleDropdown = useCallback((index) => {
    setOpenedDropdown(prev => ({ ...prev, [index]: !prev[index] }))
  }, [])

  const currentMenu = menus[router.pathname] || menus["/index"]
  const { menu, button } = currentMenu

  const renderMenuItem = useCallback((entry, index) => {
    const isDropdownOpen = openedDropdown[index]

    return (
      <div key={index} className="relative">
        {!entry.dropdown && !entry.nolink && (
          <Link href={entry.link}>
            <a
              className={`menu-item${
                entry.link === router.asPath
                  ? " hover"
                  : ""
              }`}
              target={
                entry.blank ? "_blank" : undefined
              }
            >
              {entry.label}
            </a>
          </Link>
        )}
        {!entry.dropdown && entry.nolink && (
          <a
            href={entry.link}
            className={`menu-item${
              entry.link === router.asPath
                ? " hover"
                : ""
            }`}
            target={
              entry.blank ? "_blank" : undefined
            }
          >
            {entry.label}
          </a>
        )}
        {entry.dropdown && (
          <a
            className={`menu-item${
              isDropdownOpen
                ? " hover"
                : ""
            }`}
            onClick={() => toggleDropdown(index)}
          >
            {entry.label}
          </a>
        )}
        {entry.dropdown && isDropdownOpen && (
          <div
            className="ml-[10px] mt-[30px] border border-[#0db3ac] p-[5px] pl-[10px] rounded-[5px]"
          >
            {entry.dropdown.map((o, j) => {
              return (
                <div
                  key={`${index}_${j}`}
                  className="relative mt-5 mb-5"
                >
                  <Link href={o.link}>
                    <a
                      className={`menu-item${
                        o.link === router.asPath
                          ? " hover"
                          : ""
                      }`}
                      target={
                        o.blank
                          ? "_blank"
                          : undefined
                      }
                    >
                      {o.label}
                    </a>
                  </Link>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }, [router.asPath, openedDropdown, toggleDropdown])

  return (
    <>
      <Menu
        styles={{
          bmCrossButton: {
            height: "35px",
            width: "40px"
          }
        }}
        customBurgerIcon={<img src={`${CDN_DOMAIN}/static/v2/mobile-burger.svg`} />}
        burgerButtonClassName="header-burger-button-v2"
      >
        {menu.map(renderMenuItem)}
        <div>
          <Button
            href={button.link}
            className="uec-button"
            width={button.width}
          >
            {button.label}
          </Button>
        </div>
        {!hideProButton && generateObfuscatedLink(
          "/professionnels-immobilier",
          <div style={{ display: "flex", alignItems: "center" }}>
            Nos offres pro{' '}
            <OpenInNewIcon
              style={{
                color:
                  'black',
                width: 17,
                height: 17,
                marginLeft: 4
              }}
            />
          </div>,
          "black-olink cta-pro-index",
          {
            fontSize: 14,
            marginTop: 0,
            marginBottom: 40
          }
        )}
        {!hidePriceButton && generateObfuscatedLink(
          "/services-tarifs",
          <div style={{ display: "flex", alignItems: "center" }}>
            Nos tarifs{' '}
            <OpenInNewIcon
              style={{
                color:
                  'black',
                width: 17,
                height: 17,
                marginLeft: 4
              }}
            />
          </div>,
          "black-olink cta-pro-index",
          {
            fontSize: 14,
            marginTop: 0,
            marginBottom: 40
          }
        )}
        <a
          href="tel:+33622497152"
          className="green-link !flex items-center mr-8 !no-underline text-sm"
        >
          <Avatar
            alt="Kévin Espiard"
            src={`${CDN_DOMAIN}/static/cofounders/kevin_espiard.jpg`}
            className="mr-2.5 border-2 border-color-clear-green"
          />
          <div className="flex flex-col leading-[1.2]">
            <div className="text-neutral-black">
              Une question ?
            </div>
            <div className="text-brand-primary font-semibold">
              06 22 49 71 52
            </div>
          </div>
        </a>
      </Menu>
      <div
        className="menu-v2-container flex justify-between items-center w-full px-[16px] py-[24px] sm:px-[45px] bg-color-light-100 border-b border-neutral-200 z-10"
      >
        <div className="flex-grow max-w-[160px]">
          <a href="/" className="border-0 m-auto">
            <img
              src={`${CDN_DOMAIN}/static/logo_green.png`}
              alt="Unemplacement.com"
              title="Unemplacement.com"
              className="logo-uec w-[160px]"
            />
          </a>
        </div>
        <div className="menu-v2 flex-grow-[2]">
          <ul className="ml-[35px] pl-0 pr-0">
            {menu
              .filter(item => !item.mobileOnly)
              .map((entry, i) => (
                <li
                  key={i}
                  className={entry.dropdown ? "dropdown-button" : undefined}
                >
                  {entry.dropdown && (
                    <div className="dropdown-menu">
                      <ul className="dropdown">
                        {entry.dropdown.map((o, j) => (
                          <li key={j}>
                            <a
                              className={o.customClass ? o.customClass : "dropdown-link"}
                              href={o.link}
                              target={o.blank ? "_blank" : undefined}
                            >
                              {o.label}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {!entry.nolink &&
                    <Link href={entry.link}>
                      <a
                        className={`${
                          entry.link === router.asPath
                            ? "hover"
                            : ""
                        }`}
                        target={
                          entry.blank
                            ? "_blank"
                            : undefined
                        }
                      >
                        {entry.label}
                      </a>
                    </Link>
                  }
                  {entry.nolink &&
                    <a
                      href={entry.link}
                      className={`${
                        entry.link === router.asPath
                          ? "hover"
                          : ""
                      }`}
                      target={
                        entry.blank
                          ? "_blank"
                          : undefined
                      }
                    >
                      {entry.label}
                    </a>
                  }
                  {entry.dropdown && (
                    <KeyboardArrowDownRoundedIcon style={{ color: "#2D393F" }} />
                  )}
                </li>
              ))}
          </ul>
        </div>
        <a
          href="tel:+33622497152"
          className="hideForm green-link tel-link flex items-center mr-8 !no-underline text-sm"
        >
          <Avatar
            alt="Kévin Espiard"
            src={`${CDN_DOMAIN}/static/cofounders/kevin_espiard.jpg`}
            className="mr-2.5 border-2 border-color-clear-green"
          />
          <div className="flex flex-col leading-[1.2]">
            <div className="text-neutral-black">
              Une question ?
            </div>
            <div className="text-brand-primary font-semibold">
              06 22 49 71 52
            </div>
          </div>
        </a>
        {button && (
          <ButtonWrapper
            className="hideForm flex-grow"
            maxWidth={button.width}
          >
            <Button
              href={button.link}
              className="uec-button hideConnectionButtonV2"
              width={button.width}
            >
              {button.label}
            </Button>
          </ButtonWrapper>
        )}
        {!hideProButton &&
          generateObfuscatedLink(
            "/professionnels-immobilier",
            <div className="flex items-center">
              <span className="mr-1 text-[14px]">Nos offres pro</span>
              <OpenInNewIcon
                className="text-neutral-black !w-[24px] !h-[26px] p-[5px] pl-[3px]"
              />
            </div>,
            "black-olink uec-pro-button cta-pro-index hideConnectionButtonV2",
            {
              marginLeft: 20,
              fontSize: 15
            }
          )
        }
        {!hidePriceButton &&
          generateObfuscatedLink(
            "/services-tarifs",
            <div className="flex items-center">
              <span className="mr-1 text-[14px]">Nos tarifs</span>
              <OpenInNewIcon
                className="text-neutral-black !w-[24px] !h-[26px] p-[5px] pl-[3px]"
              />
            </div>,
            "black-olink uec-pro-button cta-pro-index hideConnectionButtonV2",
            {
              marginLeft: 20,
              fontSize: 15
            }
          )
        }
      </div>
    </>
  )
}

export default UECHeader